import React from 'react'
import "./Hero.css"
import videoBg from './Igniter_V2_Hotfire_018.mp4';
import MobileHero from "./igniter-screenshot.png";
export default function Hero() {
    return (
        <div className='Hero'>
            <video className='HeroVid' src={videoBg} poster={MobileHero} autoPlay loop muted playsInline />
            <a className='HeroText'>University of <span>Florida</span></a>
            <h1 className='HeroHeader'>Florida Rocket Lab</h1>
        </div>
    );
}