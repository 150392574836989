import React from 'react';
import PropTypes from 'prop-types';
import { ImageSlider } from './ImageSlider'
import "./Modal.css";

const Modal = ({ project, onClose }) => {
    
    if (!project) {
        return null; // or some other fallback UI
    }


    return (
        <div className="prj-modal">
            <div onClick={onClose} className="prj-overlay" />
            <div className="prj-modal-content">
                <ImageSlider imageURLs={project.slideImages} />
                <div className="prj-text" >
                    <h1>{project.title}</h1>
                    <p>{project.descriptionLong}</p>
                </div>
                <div className="prj-close-modal" onClick={onClose}>x</div>
            </div>
        </div>
    );
};


Modal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    project: PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        descriptionLong: PropTypes.string.isRequired,
        mediaType: PropTypes.oneOf(["video", "youTube", "image"]).isRequired,
        mediaUrl: PropTypes.string.isRequired,
        slideImages: PropTypes.array.isRequired
    }).isRequired
};

export default Modal;
