import { useState } from "react";
import React from 'react';
import PropTypes from 'prop-types';
import "./ImageSlider.css"


export function ImageSlider({ imageURLs } ) {
    const [imageIndex, setImageIndex] = useState(0);

    const nextImage = (change) => {
        let new_set = imageIndex + change;
        if (new_set == -1){
            new_set = imageURLs.length - 1;
        }
        else if (new_set == imageURLs.length){
            new_set = 0;
        }
        setImageIndex(new_set);
    }

    return (
        <div className="slideshow-container">
            
            <div style={{width: "100%", height: "100%", display: "flex", overflow: "hidden"}}>
                {imageURLs.map((url) => (
                    <img key={url} src={url} className="prj-image" style={{translate: `${-100 * imageIndex}%`}}/>
                ))}
            </div>

            <button id="next" className="slider-button" onClick={() => nextImage(1)}>{">"}</button>
            <button id="prev" className="slider-button" onClick={() => nextImage(-1)}>{"<"}</button>
        </div>
    )
}

ImageSlider.propTypes = {
    imageURLs: PropTypes.array.isRequired
}