import "./OurTeam.css"
import React from 'react'
import Chase from "./LPDT/MacDonnell, Chase/IMG_0301.jpg"
import Alexander from "./LPDT/Bowman, Alex/IMG_0343.jpg"
import Joey from "./LPDT/Morley, Joey/IMG_0515.jpg"
import Jose from "./LPDT/Reynaldo, Jose/IMG_6563.jpeg"
import Jack from "./LPDT/Lobeck, Jack/GEOINT202405081301_59638.jpg"
import Sydney from "./LPDT/Chin, Sydney/FRL Headshot.jpg"
import Mandy from "./LPDT/Tran, Mandy/Screenshot 2024-09-24 at 7.02.14 PM.png"
import Juliet from "./LPDT/McDaniel, Juliet/D0D3C28E-7047-4F4D-9F3C-BE2882A91993.png"
import John from "./LPDT/Bromfield, John/jbromfieldheadshot.png"
import Robert from "./LPDT/Kaufman, Robert/Robert 2.jpg"



import Footer from "../Components/Footer"
//import { Button } from "@mui/material"
import people from './Information'

// import { TbBrandInstagram } from 'react-icons/tb';
// import { TbBrandTwitter } from 'react-icons/tb';
// import { TbBrandYoutube } from 'react-icons/tb';
// import { TbMail } from 'react-icons/tb';
// import { TbBrandLinkedin } from "react-icons/tb"
import { TbX } from 'react-icons/tb';
import { RiLinkedinBoxFill } from "react-icons/ri"
import { RiMailLine } from "react-icons/ri"


import { useState } from "react"
export default function OurTeam() {


  //code for the modal/popup to function
  const [modal, setModal] = useState(false);
  const [photo, setPhoto] = useState(Chase);
  const [text, setText] = useState("Nothing has been loaded.")
  const [linkedin, setLinkedin] = useState("https://www.linkedin.com/")
  const [email, setEmail] = useState("mailto:ufliquidpropulsion@gmail.com")

  function toggleModal() {
    setModal(!modal);
  }

  if (modal) {
    document.body.classList.add('active-modal')
  } else {
    document.body.classList.remove('active-modal')
  }


  return (
    <div>
      <div className="Hero4">
        <a className="HeroHeader2">Our Team</a>
        <a className="HeroText2">Florida Rocket Lab is led by a self-elected board of directors, each of whom has demonstrated dedication and leadership within their discipline.
        </a>
      </div>
      <div className="ContentBox">
        <div className="TeamContainer">
          <div className="TeamContent">
            <img src={Chase} className="TeamPhoto" onClick={()=> {setModal(!modal), setPhoto(people.Chase.photo), setText(people.Chase.text), setLinkedin(people.Chase.linkedin), setEmail(people.Chase.email)}}></img>
            <p className="TeamName">
              Chase MacDonnell
            </p>
            <p className="TeamHeader">
              President
            </p>
          </div>
          <div className="TeamContent">
            <img src={Alexander} className="TeamPhoto" onClick={()=> {setModal(!modal), setPhoto(people.Alexander.photo), setText(people.Alexander.text), setLinkedin(people.Alexander.linkedin), setEmail(people.Alexander.email)}}></img>
            <p className="TeamName">
               Alexander Bowman 
            </p>
            <p className="TeamHeader">
              Vice President
            </p>
          </div>
          
          <div className="TeamContent">
            <img src={Joey} className="TeamPhoto" onClick={()=> {setModal(!modal), setPhoto(people.Joey.photo), setText(people.Joey.text), setLinkedin(people.Joey.linkedin), setEmail(people.Joey.email)}}></img>
            <p className="TeamName">
              Joey Morley
            </p>
            <p className="TeamHeader">
              Chief Financial Officer
            </p>
          </div>
          <div className="TeamContent">
            <img src={Mandy} className="TeamPhoto" onClick={()=> {setModal(!modal), setPhoto(people.Mandy.photo), setText(people.Mandy.text), setLinkedin(people.Mandy.linkedin), setEmail(people.Mandy.email)}}></img>
            <p className="TeamName">
            Mandy Tran
            </p>
            <p className="TeamHeader">
              Systems Director
            </p>
          </div>
          <div className="TeamContent">
            <img src={Robert} className="TeamPhoto" onClick={()=> {setModal(!modal), setPhoto(people.Robert.photo), setText(people.Robert.text), setLinkedin(people.Robert.linkedin), setEmail(people.Robert.email)}}></img>
            <p className="TeamName">
              Robert Kaufman 
            </p>
            <p className="TeamHeader">
              Manufacturing Director
            </p>
          </div>
          <div className="TeamContent">
            <img src={Sydney} className="TeamPhoto" onClick={()=> {setModal(!modal), setPhoto(people.Sydney.photo), setText(people.Sydney.text), setLinkedin(people.Sydney.linkedin), setEmail(people.Sydney.email)}}></img>
            <p className="TeamName">
              Sydney Chin
            </p>
            <p className="TeamHeader">
              Fluids Director
            </p>
          </div>
          <div className="TeamContent">
            <img src={John} className="TeamPhoto" onClick={()=> {setModal(!modal), setPhoto(people.John.photo), setText(people.John.text), setLinkedin(people.John.linkedin), setEmail(people.John.email)}}></img>
            <p className="TeamName">
              John Bromfield
            </p>
            <p className="TeamHeader">
              Vehicle Engineering Director
            </p>
          </div>
          <div className="TeamContent">
            <img src={Juliet} className="TeamPhoto" onClick={()=> {setModal(!modal), setPhoto(people.Juliet.photo), setText(people.Juliet.text), setLinkedin(people.Juliet.linkedin), setEmail(people.Juliet.email)}}></img>
            <p className="TeamName">
             Juliet McDaniel
            </p>
            <p className="TeamHeader">
              Outreach Director
            </p>
          </div>
          <div className="TeamContent">
            <img src={Jose} className="TeamPhoto" onClick={()=> {setModal(!modal), setPhoto(people.Jose.photo), setText(people.Jose.text), setLinkedin(people.Jose.linkedin), setEmail(people.Jose.email)}}></img>
            <p className="TeamName">
              Jose Reynaldo
            </p>
            <p className="TeamHeader">
              Avionics Director
            </p>
          </div>
          <div className="TeamContent">
            <img src={Jack} className="TeamPhoto" onClick={()=> {setModal(!modal), setPhoto(people.Jack.photo), setText(people.Jack.text), setLinkedin(people.Jack.linkedin), setEmail(people.Jack.email)}}></img>
            <p className="TeamName">
              Jack Lobeck 
            </p>
            <p className="TeamHeader">
              Business Director 
            </p>
          </div>
         
         
         
          
        </div>
      </div>

      {modal && (
        <div className="modal">
          <div onClick={toggleModal} className="overlay"></div>
          <div className="modal-content">
            <div className="modal-box">
              <img src={photo} className="modal-photo"></img>
              <div className="modal-text">
                <p className="modal-paragraph">
                  {text}
                </p>
                {/*<p className="modal-paragraph">
                  Projects:
                </p>

                <p className="modal-paragraph">
                  &#x2022; Lander Damping Mechanism  <br></br>
                  &#x2022; Engine Gimbal System  <br></br>
                  &#x2022; Sponsor Initiative <br></br>
      </p>*/}

                {/*<Button variant="contained" sx={{
                  backgroundColor: "transparent",
                  border: "3px solid grey",
                  borderRadius: 8,
                  align: "center",
                  width: "260px",
                  fontFamily: 'Montserrat',
                  fontWeight: 'bold',
                  letterSpacing: '1px',
                  marginTop: "20px",
                  marginBottom: "20px",
                  color: "gray",
                  '&:hover': {
                    backgroundColor: '#4484b4',
                    color: 'white',
                  }
                }}
                  // onClick = {() => {
                  //     window.location.href("https://www.ufl.edu/")
                  // }}

              >Download Portfolio</Button>*/}




                <div className="modal-socials">


                  <a className="Twitch" href={linkedin} target="_blank" rel="noreferrer">
                    <RiLinkedinBoxFill className="IconText2" size="48px"></RiLinkedinBoxFill>
                  </a>
                  <a className="Twitch" href={email} target="_blank" rel="noreferrer">
                    <RiMailLine className="IconText2" size="48px"></RiMailLine>
                  </a>

                </div>


              </div>
            </div>
            <div className="close-modal" onClick={toggleModal}>
              <TbX className='close-modal-text' />
            </div>
          </div>
        </div>
      )}

      <Footer></Footer>
    </div>
  );
}