import ChasePhoto from "./LPDT/MacDonnell, Chase/IMG_0301.jpg"
import AlexanderPhoto from "./LPDT/Bowman, Alex/IMG_0343.jpg"
import JoeyPhoto from "./LPDT/Morley, Joey/IMG_0515.jpg"
import JosePhoto from "./LPDT/Reynaldo, Jose/IMG_6563.jpeg"
import JackPhoto from "./LPDT/Lobeck, Jack/GEOINT202405081301_59638.jpg"
import SydneyPhoto from "./LPDT/Chin, Sydney/FRL Headshot.jpg"
import MandyPhoto from "./LPDT/Tran, Mandy/Screenshot 2024-09-24 at 7.02.14 PM.png"
import JulietPhoto from "./LPDT/McDaniel, Juliet/D0D3C28E-7047-4F4D-9F3C-BE2882A91993.png"
import JohnPhoto from "./LPDT/Bromfield, John/jbromfieldheadshot.png"
import RobertPhoto from "./LPDT/Kaufman, Robert/Robert 2.jpg"



const people = {
    Chase: {
        photo: ChasePhoto,
        text: "Chase MacDonnell is a fourth-year computer science major serving as the President of Florida Rocket Lab. As a VP, he has played a crucial role in designing a robust and reliable control system for the Sparrow Engine and coordinating its integration across all sub-teams. Outside of FRL, he enjoys mentoring in the FIRST Robotics Competition and working on personal projects.",
        linkedin: "https://www.linkedin.com/in/chase-macdonnell/",
        email:"mailto:chase.macdonnell@floridarocketlab.org"
    },
    Alexander: {
        photo: AlexanderPhoto,
        text: "Alexander Bowman is the vice-president and acting engine design director at FRL, where he has been for the past two years. As vice president Alexander acts as the project manager, ensuring that priorities are communicated to directors and issues from individual subteams are known. In his free time, Alexander enjoys reading about history, fancy cooking, and aviation photography.",
        linkedin: "https://www.linkedin.com/in/alexander-bowman-2350861b7/",
        email:"mailto:alexander.bowman@ufl.edu"
    },
    Joey: {
        photo: JoeyPhoto,
        text: "Joey Morley is a fourth-year mechanical engineering student. Joey joined in January 2023 where he served as the team's vehicle engineering director until December 2023. Joey now serves as the Chief Finance Officer for the team, managing team funds, working with the business and outreach teams, and helping direct the team to its long-term goals.",
        linkedin: "https://www.linkedin.com/in/joey-morley/",
        email:"mailto:jmorley@ufl.edu"
    },
    Jose: {
        photo: JosePhoto,
        text: "Jose Reynaldo is a second-year computer science major who joined the team in the fall of his freshman year. As the Avionics Director, he leads the development of a custom flight computer and for next-generation flight vehicles Hopper and Skipper, overseeing the integration of advanced software and hardware systems with a focus on safety, real-time control, and high-speed communication. He also spearheads recruitment efforts, expanding the sub-team. Outside of his leadership role, Jose enjoys keeping up with aerospace advancements, reading novels, working out, and competing in triathlons.",
        linkedin: "https://www.linkedin.com/in/jose-reynaldo/",
        email:"mailto:jose.reynaldoher@ufl.edu"
    },
    Jack: {
        photo: JackPhoto,
        text: "Jack Lobeck is a fourth-year Plant Science major with a minor in Professional Selling. As the Business Director of Florida Rocket Lab, he is responsible for fostering relationships with industry partners to secure sponsorships, mentorships, and internship and employment opportunities for members. With four years of experience in sales, Jack is currently a Business Development Intern at Satlantis US, a Gainesville-based space technology company. Outside of work and FRL, Jack enjoys connecting with nature through hikes and trail running, and studying plants and fungi.",
        linkedin: "https://www.linkedin.com/in/jack-lobeck/",
        email:"mailto:j.lobeck@ufl.edu"
    },
    Sydney: {
        photo: SydneyPhoto,
        text: "Sydney Chin is a third-year aerospace engineering major. Joining FRL in her freshman year, she has been involved with both the fluids and safety sub-teams. As a fluids safety representative, she oversaw writing the procedures for the igniter hotfire and subsequently serving as the test operator for multiple igniter tests. Starting Fall 2024, Sydney has started the new role of Fluids Director where she is helping her team design, build, and test the propellant delivery system for Sparrow and eventually Hopper. Outside of FRL, Sydney enjoys philosophy, travel, and cooking.",
        linkedin: "https://www.linkedin.com/in/sydney-chin-a57a41232/",
        email:"mailto:chin.sydney@ufl.edu"
    },
    Mandy: {
        photo: MandyPhoto,
        text: "Mandy Tran is a fourth year Mechanical Engineering student. As a member of FRL, she has been dedicating her time as a Safety Member for 2 years before becoming the Director of Systems Safety. She has previously worked on several igniter hot-fires for the team and now leads a small team that works on mitigating risks and hazards throughout FRL. Outside of engineering, she enjoys backpacking, hiking, and traveling. You can sometimes catch her studying at the Reitz Student Union or volunteering.",
        linkedin: "https://www.linkedin.com/in/mandyvytran/",
        email:"mailto:mtran2@ufl.edu"
    },
    Juliet: {
        photo: JulietPhoto,
        text: "Juliet McDaniel is a second-year aerospace engineering student. She has spent the last three semesters working on the engine design subteam to help develop the Sparrow engine, and is now the director of Outreach. In this position she manages all of FRL's social media accounts, as well as working with the business team and CFO to fundraise. In her free time, Juliet reads science fiction and fantasy, plays clarinet, and builds Lego.",
        linkedin: "https://www.linkedin.com/in/julietemcdaniel/",
        email:"mailto:Juliet.mcdaniel@ufl.edu"
    },
    John: {
        photo: JohnPhoto,
        text: "John Bromfield is a third-year aerospace engineering major. He has served as the Vehicle Engineering Director since January ‘24 after joining the team in August ‘23. In this role, he manages a team of 20 student engineers working to design a reusable VTVL flight vehicle (Hopper), design and manufacture experimental propellant tanks, and develop a preliminary controls system to control the thrust vectoring of the engine.   He is also one of the test operators for our team, contributing to igniter hot fires, injector cold flows, and hydrostatic testing. Outside of the team, he enjoys participating in a variety of intramural sports, especially soccer and pickleball.",
        linkedin: "https://www.linkedin.com/in/john-bromfield-ufl/",
        email:"mailto:Johnbromfield@ufl.edu"
    },
    Robert: {
        photo: RobertPhoto,
        text: "Robert Kaufman is a fifth-year Mechanical and Aerospace Engineering Dual Major. As a member of FRL he has spent the last two year as a member of the manufacturing sub-team and now serves as the Manufacturing Director. Robert supervises the machining of the components for the engine, ensuring that the parts are made within specifications while following all safety protocols. Additionally, he establishes and implements both training procedures for new members and documentation methods, to ensure the longevity and continued expansion of the FRL's manufacturing capabilities. Outside of FRL, Robert enjoys hiking and wildlife photography.",
        linkedin: "https://www.linkedin.com/in/robert-kaufman-86904021b/",
        email:"mailto:robertkaufman1@ufl.edu"
    }

  


}

export default people;