
import * as React from 'react';
import "./Homepage.css";
import Typography from "@mui/material/Typography";
import Hero from "../Components/Hero";
import Footer from "../Components/Footer";
import Cards from "../Components/Cards";

//images
import Trailer from "../Images/Trailer/trailer_outside.png";
import TeamPhoto from "../Images/TeamPhoto2_1.jpg";
import SparrowEngine from "../Images/Sparrow/sparrow_apart.png";


// const sampleText = "This is sample text. This is sample text. this is sample text.";


// import UFMonogram from "../Images/UF_Monogram.png";
// import MWLogo from "../Images/Meridian_Waste_Logo.png";
export default function Homepage() {
  //const windowWidth = window.innerWidth;
  return (
    <>
      <Hero></Hero>

      <div className="InfoContainer">
        <div className="InfoWrapper">
          <div className="InfoRow1">
            <div className="Column1">
              <Typography sx={{ fontSize: "42px", fontFamily: "Noto Sans", fontWeight: "bold", color: "#383838", }}>
                What is FRL?
              </Typography>
              <Typography sx={{ fontSize: 20, fontFamily: "Noto Sans", color: "#383838", textAlign: "content", marginTop: "12px" }}>
              As a team, we specialize in liquid rocket propulsion systems. This year, we plan to become the first undergraduate team to launch and land a liquid-propelled rocket capable of generating 500 lbs of thrust. To achieve this goal, we have designed a hopper system we hope to iteratively improve using feedback from industry design reviews and months of student testing. We will be using this design to represent the University of Florida in the first-ever Collegiate Propulsive Lander Competition.
              </Typography>
            </div>
          </div>
        </div>
      </div>
      
      {/* <div className="SponsorContainer">
        <div className="SponsorWrapper">
          <div className="SponsorRow1">
            <div className="SponsorColumn1">
              <Typography sx={{ fontSize: "42px", fontFamily: "Noto Sans", fontWeight: "bold", color: "#383838", }}>
                Sponsors
              </Typography>
              <Typography className="Sponsors">
                <ul className="BluefireSponsors">
                <p>Bluefire Tear:</p>
                  <li>Ansys</li>
                  <li>UF MAE</li>
                  <li>Meridian Waste</li>
                  <li>Celsius</li>
                </ul>
              </Typography>
            </div>
          </div>
        </div>
      </div> */}

      <div className="HomepageContentBox">
        <div className="cards__container">
          <Cards prjNum="0" location="/projects" color="#ffffff" title="Sparrow Engine" subtitle="PROJECTS" text="Check out our entry into the Collegiate Propulsive Lander Competition. We're approaching our first hot-fire for our 550lb Sparrow engine." img={SparrowEngine}></Cards>
          <Cards prjNum="NA" location="/ourteam" color="#ffffff" title="The Team" subtitle="OTHER" img={TeamPhoto} text="Florida Rocket Lab is a group of talented individuals coming together from different backgrounds and perspectives. Click here to meet the team."></Cards>
          <Cards prjNum="1" location="/projects" color="#ffffff" title="Trailer Mobile Testbed" subtitle="LATEST UPDATES" img={Trailer} text="We recently acquired the basis for our future engine testing operations! Check out our future plans here."></Cards>
        </div>

      </div>
      <Footer></Footer>
    </>
  );
}