import React from 'react'
import "./Info.css"
import PropTypes from 'prop-types';


export default function Info(props) {
    return (
        <div>
            <div className={props.cn}>
                <div className="InfoWrap">
                    <div className={props.order}>
                        <div className="Col1">
                            <div className="TextWrap" >
                                <h1 className={props.headingColor}>{props.heading}</h1>
                                <hr className="rounded" color = {props.color}></hr>
                                <p className="Text">{props.text}</p>
                            </div>
                        </div>
                        <div className="Col2">
                            <img src={props.image} className="Image"></img>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

Info.propTypes = {
    cn: PropTypes.string,
    text: PropTypes.string,
    image: PropTypes.any,
    order: PropTypes.any,
    heading: PropTypes.any,
    headingColor: PropTypes.string,
    color: PropTypes.string
}

