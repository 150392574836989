import * as React from 'react';
import Info from '../Info';
//import MWLogo from "../Images/Meridian_Waste_Logo.png";
import Footer from "../Components/Footer";
import sections from './AboutData';
import "./About.css"


export default function Page1() {

  return (

    //The plan is to do a half-height hero. DON'T FORGET!
    <>
      <div className = "Hero2">
        <a className = "HeroHeaderSolo">About Us</a>
      </div>
      <Info cn="InfoCont" text={sections[0].Text} image={sections[0].Image} order="InfoRowT" heading={sections[0].Heading} headingColor="HeadingBlack" color="383838">
      </Info>
      <Info cn="InfoContBlack" text={sections[1].Text} image={sections[1].Image}  order="InfoRowI" heading={sections[1].Heading} headingColor="HeadingWhite" color ="white">
      </Info>
      <Info cn="InfoCont" text={sections[2].Text} image={sections[2].Image}  order="InfoRowT" heading={sections[2].Heading} headingColor="HeadingBlack" color="383838">
      </Info>
      <Info cn="InfoContBlack" text={sections[3].Text} image={sections[3].Image}  order="InfoRowI" heading={sections[3].Heading} headingColor="HeadingWhite " color ="white">
      </Info>
      <Info cn="InfoCont" text={sections[4].Text} image={sections[4].Image}  order="InfoRowT" heading={sections[4].Heading} headingColor="HeadingBlack" color="383838">
      </Info>
      <Footer></Footer>
    </>

  );
}
