import * as React from 'react';
import "./Sponsors.css"
//import { Button } from '@mui/material';
import Footer from "../Components/Footer";

import SwageLockLogo from "./Carousel/Swagelok_Logo.jpg";
import ThyssenKruppLogo from "./Carousel/thyssenkrupp_logo.png";
import BasicMicroLogo from "./Carousel/Basicmicro_mc_logo.jpg";
import AquaEnviLogo from "./Carousel/aquaenvironments_logo.png";
import IFMLogo from "./Carousel/ifm_elektronic_logo.webp";
import DassultLogo from "./Carousel/dassult_logo.png";
import MWLogo from "./Carousel/Meridian_Waste_Logo.png";
import UFLogo from "./Carousel/UF_Monogram.png";
import AnsysLogo from "./Carousel/ANSYS_logo.png";



export default function Sponsors() {

    return (

        <div>
            <div className="Hero6">
                <a className="HeroHeader2">Our Sponsors</a>
                <a className="HeroText2">We would like to extend our heartfelt thanks to our generous contributors. Your support is what gets us closer to space.</a>
            </div>
            <div className="SponsorIntroBox">
                <div className="SponsorContainer">
                <div className="SponsorRow">
                        <img src={SwageLockLogo} className="sponsor-photo"></img>
                        <div className="SponsorTextBox">
                            <p className = "SponsorHeaderTitle">Swagelok</p>
                            <p className = "SponsorText">Swagelok delivers world-class fluid system components, providing innovative and reliable solutions that power engineering excellence and precision.</p>
                        </div>
                    </div>
                    <div className="SponsorRow">
                        <img src={ThyssenKruppLogo} className="sponsor-photo"></img>
                        <div className="SponsorTextBox">
                            <p className = "SponsorHeaderTitle">Thyssen Krupp</p>
                            <p className = "SponsorText">A global engineering powerhouse, ThyssenKrupp drives innovation with advanced materials and technologies, shaping the future of engineering solutions.</p>
                        </div>
                    </div>
                    <div className="SponsorRow">
                        <img src={BasicMicroLogo} className="sponsor-photo"></img>
                        <div className="SponsorTextBox">
                            <p className = "SponsorHeaderTitle">Basic Micro</p>
                            <p className = "SponsorText">Specializing in sensor and control solutions, Basic Micro provides cutting-edge technologies that enhance precision and automation in advanced systems.</p>
                        </div>
                    </div>
                    <div className="SponsorRow">
                        <img src={AquaEnviLogo} className="sponsor-photo"></img>
                        <div className="SponsorTextBox">
                            <p className = "SponsorHeaderTitle">Aqua Environment</p>
                            <p className = "SponsorText">Designing and manufacturing high-pressure valves and components since 1976, Aqua Environment delivers reliable solutions for critical gas and liquid handling applications.</p>
                        </div>
                    </div>
                    <div className="SponsorRow">
                        <img src={IFMLogo} className="sponsor-photo"></img>
                        <div className="SponsorTextBox">
                            <p className = "SponsorHeaderTitle">IFM</p>
                            <p className = "SponsorText">A leader in industrial automation, IFM provides innovative sensor and IIoT solutions that enhance productivity and efficiency in various applications.</p>
                        </div>
                    </div>
                    <div className="SponsorRow">
                        <img src={DassultLogo} className="sponsor-photo"></img>
                        <div className="SponsorTextBox">
                            <p className = "SponsorHeaderTitle">Dassault Systèmes</p>
                            <p className = "SponsorText">A pioneer in virtual twin technology, Dassault Systèmes empowers sustainable innovation by transforming how products are designed, simulated, and delivered through its 3DEXPERIENCE platform.</p>
                        </div>
                    </div>
                    <div className="SponsorRow">
                        <img src={MWLogo} className="sponsor-photo"></img>
                        <div className="SponsorTextBox">
                            <p className = "SponsorHeaderTitle">Meridian Waste</p>
                            <p className = "SponsorText">Meridian Waste is a trash collection and recycling company with a less wasteful approach to garbage. They provide convenient curbside waste removal services that you can rely on.</p>
                        </div>
                    </div>

                    <div className="SponsorRow">
                        <img src={AnsysLogo} className="sponsor-photo"></img>
                        <div className="SponsorTextBox">
                            <p className = "SponsorHeaderTitle">Ansys</p>
                            <p className = "SponsorText">Ansys offers a comprehensive software suite that spans the entire range of physics, providing access to virtually any field of engineering simulation that a design process requires.</p>
                        </div>
                    </div>
                    <div className="SponsorRow">
                        <img src={UFLogo} className="sponsor-photo"></img>
                        <div className="SponsorTextBox">
                            <p className = "SponsorHeaderTitle">UF MAE</p>
                            <p className = "SponsorText">The Department of Mechanical and Aerospace Engineering  at the University of Florida is responsible for a multitude of undergraduate and graduate degrees in various engineering disciplines.</p>
                        </div>
                    </div>
                   
                </div>

            </div>


            <Footer></Footer>
        </div>
    );
}