import AboutPH from "../Images/OurFacilities.jpg";
// import Leadership from "../Images/Leadership.jpg";
import Manufacturing from "../Images/Manufacturing.jpg";
import Engine from "../Images/SystemsPlaceholder.png";
import TeamHistory from"../Images/teamhistory.jpg";
import Log from "../Images/IMG_8870_crop.jpg"

const sections = {
    0: {
        Heading: "Our Facilities",
        Text: "Our main base of operations is the UF Energy Research and Education Park. Here we conduct all component testing and validation. Our other testing facility is in Orlando, where all hot-fires are undertaken to maximize team safety as well as the safety of our surroundings. Our main (non-test-related) meeting area is Room 313 in the Mechanical and Aerospace Engineering Building-A. Feel free to stop by and say hello!",
        Image: AboutPH,
    },

    1: {
        Heading: "Our Experience",
        Text: "As one of the University of Florida's premier design teams, we pride ourselves on our talented and diverse membership with experience in a multitude of fields. We currently have over 70 active members in various subteams including Avionics, Business, Engine Design, Safety, Vehicle Engineering, and manufacturing. With so many areas to specialize in, prospective members with any skill set and at any level in their college journey are welcome to join.",
        Image: Log,
    },

    2: {
        Heading: "Systems",
        Text: "As a full engine design team, all rocket-engine systems are included in our work. Our propellant delivery designers focus on how to move propellants from the tank into the chamber using pressurized gas propellant feed systems and pump propellant feed systems. We also design the engine chamber and nozzle, the injector, and all electronics. Beyond design, we use Computational Fluid Dynamics (CFD) software to simulate fluid flow through all rocketry components.",
        Image: Engine,
    },

    3: {
        Heading: "The Manufacturing Process",
        Text: "Our manufacturing team cooperates with all other sub-teams to efficiently create all required parts from various materials. Our manufacturing team's primary responsibilities include researching manufacturing techniques, solid model and drawing generation, and experience with manual and CNC machining. We use industry-grade mills, lathes, and CNC machines to prototype and manufacture almost all of our engine parts in-house.",
        Image: Manufacturing,
    },

    4: {
        Heading: "Team History",
        Text: "FRL's first propulsion system developent effort was with a software verification engine. the SVE's primary objectives were to validate the software and design methods used in development and to serve as a proof-of-concept for a low-cost propulsion system. The next project undertaken was the Albatross ground-test engine, intended to bridge the gap in performance between the SVE and a flight-capable engine. The Albatross was meant to provide valuble experience with propellents and manufacturing techniques. In 2019, we shifted towards achieving combustion with readily available and student-safe propellants, which remains the main focus of our current engine designs.",
        Image: TeamHistory,
    }
}

export default sections;